import * as React from 'react';
import { List, Datagrid, TextField, UrlField } from 'react-admin';

export const TaxonomyList = (props) => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <UrlField source="url" />
            <TextField source="id" />
            <TextField source="label" />
            <TextField source="annotations" />
        </Datagrid>
    </List>
);
