import { useLogin } from 'react-admin';

import { UserManager } from 'oidc-client-ts';

import userManagerSettings from './userManager';
import { useEffect } from 'react';

const userManager = new UserManager(userManagerSettings);

// "Login Page" more like a midway point between react admin checking auth status
// and then redirecting to the keycloak login page
const LoginPage = (params) => {

    const login = useLogin();

    useEffect(() => {
        // On first load login
        login();
    }, []);

    // This is a jump page to redirect the user to keycloak
    return null;
};

export default LoginPage;
