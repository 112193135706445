import * as React from "react";
import { Card, CardContent, CardHeader } from "@material-ui/core";

export default () => (
  <Card>
    <CardHeader title="Welcome to the Rock Art Administration Tool" />
    <CardContent>
      This tool allows users to create collections and add images.
    </CardContent>
  </Card>
);
