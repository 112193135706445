import React, { useState } from 'react';
import {
    useListContext,
    Button,
    SimpleForm,
    ReferenceInput,
    AutocompleteInput,
    useDataProvider,
    useNotify,
    useRefresh,
    useUnselectAll,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

// Button component that displays a modal that allows the user to select a collection.
// When submitting the modal, the selected images are added to the collection.
function BulkAddCollectionButton() {
    const { selectedIds } = useListContext();
    const [showDialog, setShowDialog] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll('images');

    // Show modal
    const handleClick = () => {
        setShowDialog(true);
    };

    // Hide modal
    const handleCloseClick = () => {
        setShowDialog(false);
        unselectAll();
    };

    const dataProvider = useDataProvider();

    // Set images selected images to belong to the selected collection
    const collectionSave = async (values) => {
        const data = {collection_ids: [values?.collection_id]}
        await dataProvider.addManyCollectionImage('images', { ids: selectedIds, data: data}).then(()=>{
            notify('Collection(s) added');
        }).catch((err)=>{
            notify('Error: Collection(s) not added', { type: 'warning' })
        });
        setShowDialog(false);
        refresh()
    }

    return (
        <>
            <Button onClick={handleClick} label="Add to Collection">
                <IconContentAdd />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Create post"
            >
                <DialogTitle>Add to Collection</DialogTitle>

                <SimpleForm onSubmit={collectionSave}>
                <ReferenceInput label="Post" source="collection_id" reference="collections" >
                    <AutocompleteInput optionText="title" fullWidth />
                </ReferenceInput>
                </SimpleForm>
            </Dialog>
        </>
    );
}

export default BulkAddCollectionButton;
