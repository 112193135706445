import * as React from 'react';
import { Container } from '@mui/system';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CollectionsTutorials from './tutorials/CollectionsTutorials'
import ImagesTutorials from './tutorials/ImagesTutorials'


export const TutorialPage = (props) => (
    <div style={{marginTop:"15px"}}>
    <h1>Tutorials</h1>
    <Container>
          <h2>Collections - basic usage</h2>
          {/* <p>PUT SOME NOTES HERE</p> */}
          <CollectionsTutorials />
    </Container>
    <Container>
          <h2>Images - basic usage</h2>
          {/* <p>PUT SOME NOTES HERE</p> */}
        <ImagesTutorials />
    </Container> 
    </div>
);
