import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  regex,
  useInput,
  Toolbar,
  SaveButton,
  DeleteButton,
} from "react-admin";
import { BulkCollectionDeleteManyButton } from "./customActions/BulkCollectionDeleteManyButton";

export const CollectionList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit" bulkActionButtons={<BulkCollectionDeleteManyButton />}>
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="notes" />
      <TextField source="pin" />
      <EditButton />
    </Datagrid>
  </List>
);

const CollectionTitle = ({ record }) => {
  return <span>Image {record ? `"${record.title}"` : ""}</span>;
};

const validatePin = regex(/^\d{4}$/, 'Must be a 4 digits PIN number');

const CustomToolbar = props => (
  <Toolbar
      {...props}
      sx={{ display: 'flex', justifyContent: 'space-between' }}
  >
      <SaveButton />
      <DeleteButton mutationMode="pessimistic" />
  </Toolbar>
);

export const CollectionEdit = (props) => (
  <Edit title={<CollectionTitle />} {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput source="title" />
      <TextInput source="notes" />
      <TextInput source="pin" validate={validatePin} /> 
      <span>Enter 4 digits PIN to lock collection</span>
    </SimpleForm>
  </Edit>
);

export const CollectionCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="notes" />
      <TextInput source="pin" />
      <span>Enter 4 digits PIN to lock collection</span>
    </SimpleForm>
  </Create>
);
