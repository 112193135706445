import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "react-oidc-context";
import { WebStorageStateStore } from 'oidc-client-ts';

const oidcConfig = {
  authority: window?.__RUNTIME_CONFIG__?.authority,
  client_id: window?.__RUNTIME_CONFIG__?.client_id,
  redirect_uri: window?.__RUNTIME_CONFIG__?.redirect_uri,
  loadUserInfo: true,
  stateStore: new WebStorageStateStore({ store: window.localStorage }),
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: false,
  accessTokenExpiringNotificationTimeInSeconds: 60
};

const container = document.getElementById('app');
const root = createRoot(document.getElementById('root'))
root.render(
  <AuthProvider {...oidcConfig}>
  <App />
</AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
