const devEnvironment = process.env.NODE_ENV !== 'production'

const issuer = devEnvironment ? 'http://localhost:8080/realms/rockartdev' : process.env.REACT_APP_AUTH_URL;
const clientId = devEnvironment ? 'rockart' : process.env.REACT_APP_CLIENT_ID;
const redirectUri = window.location.origin + '/#/login';
const logoutRedirectUri = window.location.origin + '/#/login';

const userManagerSettings = {
    authority: issuer,
    client_id: clientId,
    redirect_uri: redirectUri,
    loadUserInfo: true,
    post_logout_redirect_uri: logoutRedirectUri,
    response_type: 'code',
    scope: 'openid', // Allow to retrieve the email and user name later api side
};

export default userManagerSettings;
