import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Create01 from './images/image_create01.jpg';
import Create02 from './images/image_create02.jpg';
import Edit01 from './images/image_edit01.jpg';
import Edit02 from './images/image_edit02.jpg';
import ShowView from './images/image_showview.jpg';
import MultiSelectCollection from './images/image_multiselect.jpg';
import DeselectCollection from './images/image_deselect.jpg';
import SaveImage from './images/image_save.jpg';
import AddToCollection01 from './images/image_add_collection01.jpg';
import AddToCollection02 from './images/image_add_collection02.jpg';
import AddToCollection03 from './images/image_add_collection03.jpg';


const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
}));

const ImageItem = styled('img')({
  width:'100%',
});

const TextItem = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body1,
  padding: theme.spacing(2),
  textAlign: 'left',
  color: theme.palette.text.primary,
  boxShadow: 'none',
}));

export default function ImagesTutorials() {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content-images"
          id="panel1a-header-images"
        >
          <Typography>Upload images</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box>
              <Grid container spacing={2}>
                <Grid md={3}>
                  <TextItem>
                    <h4>Step 1.</h4>
                    <p>Click "Images" in the menu on the left to browse images</p>
                    <h4>Step 2.</h4>
                    <p>Click "CREATE" button at top of the table to enter create view</p>
                    <h4>Step 3.</h4>
                    <p>Click on the area to browse and upload image or alternatively drag and drop an image to the grey area</p>
                    <h4>Step 4.</h4>
                    <p>Click "Save" button at the bottom. </p>
                    <p>Done.</p>
                  </TextItem>
                </Grid>
                <Grid md={9}>
                  <Item><ImageItem src={Create01} /></Item>
                  <Item><ImageItem src={Create02} /></Item>
                </Grid>
              </Grid>
            </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content-images"
          id="panel2a-header-images"
        >
          <Typography>Add image to single collection or multiple collections</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box>
              <Grid container spacing={2}>
                <Grid md={3}>
                  <TextItem>
                    <h4>Step 1.</h4>
                    <p>Click "Edit" on the right.</p>
                    <p>note <sup>*</sup>: If you click on the table row, you will be brought the show view instead. Click "EDIT" button on the top right to enter edit view</p>
                    <h4>Step 2.</h4>
                    <p>Click "Collection" field to open the dropdown list.</p>
                    <h4>Step 3.</h4>
                    <p>Click and select the collection(s)</p>
                    <h4>Step 4.</h4>
                    <p>Click "Save" button at the bottom. </p>
                    <p>Done.</p>
                  </TextItem>
                </Grid>
                <Grid md={9}>
                  <Item><ImageItem src={Edit01} /></Item>
                  <Item><p style={{textAlign:'left'}}>note<sup>*</sup>: Show view</p><ImageItem src={ShowView} /></Item>
                  <Item><ImageItem src={Edit02} /></Item>
                  <Item>
                    <h4 style={{color:'red',textAlign:'left'}}>Step 3.</h4>
                    <div><p style={{textAlign:'left'}}>Select / Multi select collection</p><ImageItem src={MultiSelectCollection} /></div>
                    <div><p style={{textAlign:'left'}}>Deselect collection</p><ImageItem src={DeselectCollection} /></div>
                  </Item>
                  <Item style={{textAlign:'left'}}>
                    <h4 style={{color:'red',textAlign:'left'}}>Step 4.</h4>
                    <ImageItem style={{width:'50%'}} src={SaveImage} />
                  </Item>
                </Grid>
              </Grid>
            </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content-images"
          id="panel3a-header-images"
        >
          <Typography>Add multiple images to a collection</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box>
              <Grid container spacing={2}>
                <Grid md={3}>
                  <TextItem>
                    <h4>Step 1.</h4>
                    <p>Tick the checkboxes to select the images that need to assign to a collection.</p>
                    <h4>Step 2.</h4>
                    <p>Click "ADD TO COLLECTION" button at top right of the table.</p>
                    <h4>Step 3.</h4>
                    <p>Select the collection from the dropdown list.</p>
                    <h4>Step 4.</h4>
                    <p>Click "Save" button </p>
                    <p>Done.</p>
                  </TextItem>
                </Grid>
                <Grid md={9}>
                  <Item><ImageItem src={AddToCollection01} /></Item>
                  <Item><ImageItem src={AddToCollection02} /></Item>
                  <Item><ImageItem src={AddToCollection03} /></Item>
                </Grid>
              </Grid>
            </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
