const authProvider = (auth) => {

    // Go to oidc login page, triggered by react admin login button or hook, currently unused
    // as browser is redirected to oidc login when no logged in user
    const handleLogin = () => {
        console.log('login');
        return auth.signinRedirect();
    }

    // called when the API returns an error
    // rejecting this will cause a logout to trigger.
    const handleError = (status) => {
        console.log('error');
        if (status === 401 || status === 403) {
            return Promise.reject();
        }
        return Promise.resolve();
    }

    // Called when the user navigates to a new location, to check for authentication
    // rejecting this will cause a logout to trigger.
    const handleAuth = () => {
        console.log('auth');
        // Check if auth user exists
        return auth.user ? Promise.resolve() : Promise.reject()
    }

    // called when the user clicks on the logout button, or when triggered by other means (read below)
    const handleLogout =  () => {
        console.log('logout')
        return auth.revokeTokens(["access_token", "refresh_token"]).then(() => {
            console.log('tokens revoked')
            return auth.removeUser();
        }).catch(err => Promise.reject("Logout Error: err"));
    }

    // Can be called manually with useGetIdentity hook
    // rejecting this will cause a logout to trigger.
    // Otherwise RA uses it to display current logged-in user
    const handleIdentity = () => {
        console.log('identity');
        const userProfile = auth?.user?.profile;
        if (userProfile){
            const userIdentity = {
                fullName: userProfile.name,
                userName: userProfile.preferred_username,
                token: userProfile.access_token
            };
            return Promise.resolve(userIdentity);
        }else{
            return Promise.reject("User Profile not found");
        }
    }

    // Called when the user navigates to a new location, to check for permissions / roles
    // rejecting this will cause a logout to trigger.
    const handlePermissions = () => {
        console.log('permission');
        const userProfile = auth?.user?.profile;
        if (userProfile){
            const userPermissions = { groups: userProfile.groups };
            return Promise.resolve(userPermissions);
        } else {
            return Promise.reject("Permissions not found");
        }
    }

    return {
        login: () => handleLogin(),
        checkError: ({ status }) => handleError(status),
        checkAuth: () => handleAuth(),
        logout: () => handleLogout(),
        getIdentity: () => handleIdentity(),
        getPermissions: () => handlePermissions(),
    }
}

export default authProvider;
