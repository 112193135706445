// in src/App.js
import * as React from 'react';
import { AnnotationList } from './annotations';
import { TaxonomyList } from './taxonomies';
import { ImageEdit, ImageList, ImageCreate, ImageShow } from './images';
import {
    CollectionCreate,
    CollectionEdit,
    CollectionList,
} from './collections';
import { Admin, Resource, fetchUtils, ListGuesser, ShowGuesser } from 'react-admin';
import { customDataProvider } from './dataproviders/customProvider';
import ImageIcon from '@mui/icons-material/Image';
import CollectionsIcon from '@mui/icons-material/Collections';
import Dashboard from './dashboard';
import authProvider from './authProvider';
import { useAuth } from 'react-oidc-context';
import AuthEvents from './AuthEvents';
import { createHashHistory } from 'history';
import LoginPage from './LoginPage';
import { TutorialPage } from './tutorials';

// Use environment variables to set server url
let serverURL = window?.__RUNTIME_CONFIG__?.api_uri;

const App = () => {
    // Auth context
    const auth = useAuth();
    const [authInitialising, setAuthInitialising] = React.useState(true);

    // React admin http client, this is used by all react admin backend queries,
    // we need to append bearer token from the auth context
    const httpClient = (url, options = {}) => {
        if (!options.headers) {
            options.headers = new Headers({ Accept: 'application/json' });
        }
        const token = auth.user?.access_token;
        options.headers.set('Authorization', `Bearer ${token}`);
        return fetchUtils.fetchJson(url, options);
    };

    // Custom data provider for interacting with backend api
    const dataProvider = customDataProvider(serverURL, httpClient);

    // this might need some work but on a page reload, the react oidc context needs
    // a few moments to fully load. in that time the axios interceptor will not be configured
    // and axios requests will not have a token attached, even when the user is logged in.
    // rather than making EVERY useEffect hook depend on auth.isLoading, this should hopefully fix this
    React.useEffect(() => {
        if (authInitialising && !auth.isLoading) {
          setAuthInitialising(false);
        }
      }, [auth.isLoading, authInitialising, setAuthInitialising]);

    // Authprovider instance thats created with useAuth Context
    const newAuthProvider = authProvider(auth);

    return (
        <>
        <AuthEvents />
        {authInitialising ? null : (
            <Admin
            dashboard={Dashboard}
            disableTelemetry
            dataProvider={dataProvider}
            authProvider={newAuthProvider}
            loginPage={LoginPage}
            requireAuth
            >
                <Resource
                    name="collections"
                    list={CollectionList}
                    edit={CollectionEdit}
                    create={CollectionCreate}
                    icon={CollectionsIcon}
                />
                <Resource
                    name="images"
                    list={ImageList}
                    edit={ImageEdit}
                    create={ImageCreate}
                    show={ImageShow}
                    icon={ImageIcon}
                />
                <Resource name="annotations" list={AnnotationList} />
                <Resource name="taxonomies" list={TaxonomyList} />
                <Resource name="tutorial" list={TutorialPage} />
            </Admin>
            )
        }
        </>
    )
    };

export default App;
