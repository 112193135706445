import * as React from 'react';
import { List, Datagrid, TextField, UrlField, DateField, ReferenceField, BooleanField, ReferenceArrayField, SingleFieldList, ChipField } from 'react-admin';

export const AnnotationList = (props) => (
    <List {...props}>
       <Datagrid rowClick="edit">
            <TextField source="id" label="Annotation ID" />
            <TextField source="text" label='Taxonomy'/>
            <ReferenceField label="Image" reference="images" source="image_id" link='show'>
                <ChipField source="title" />
            </ReferenceField>
            <ReferenceField label="Collection" reference="collections" source="collection_id">
                <ChipField source="title" />
            </ReferenceField>
            <DateField source="created" />
            <DateField source="updated" />
        </Datagrid>
    </List>
)
