import drfProvider from './djangoProvider'
export const customDataProvider = (apiURL, httpClient) => {
    const dataProvider = drfProvider(apiURL, httpClient);
    return {
        ...dataProvider,
        getList: (resource, params) => {
            // Change filter target from collections_id to collections
            if(resource === "images"){
                if (params?.filter?.collections_id){
                    const filter = {
                        ...params.filter,
                        collections: params.filter.collections_id
                    }
                    const newParams = {
                        ...params,
                        filter: filter
                    }
                    return dataProvider.getList(resource, newParams);
                }
            }
            return dataProvider.getList(resource, params);
        },
        // Adds each provided image to the provided collection
        addManyCollectionImage: (resource, params) => {
            return Promise.all(
                params.ids.map(id =>
                  httpClient(`${apiURL}/${resource}/${id}/add_to_collection/`, {
                    method: 'POST',
                    body: JSON.stringify(params.data),
                  })
                )
              ).then(responses => ({ data: responses.map(({ json }) => json.id) }))
        },
        // create many image models with one image request, using each provided image binary
        createMany: (resource, params) => {
            // Override create only for image resource
            const formData = new FormData();
            const data = params;
            if(data['collection_ids']){
                data['collection_ids'].forEach((collection) => {
                    formData.append('collection_ids', collection);
                })
            }
            const image_objects = data['image_object'];
            image_objects.forEach((image_object) => {
                formData.append('image_object', image_object.rawFile);
            });

            // Construct target resource url
            const targetUrl = `${apiURL}/${resource}/`;
            return httpClient(targetUrl, {
                method: 'POST',
                body: formData
            })
                .then(({json}) => {
                    //Wrap response json
                    return { data: json };
                });
        },
        update: (resource, params) => {

            // Override update only for image resource
            if (resource !== 'images') {
                return dataProvider.update(resource, params);
            }

            const formData = new FormData();
            const data = params.data;
            // Override update only for image resource
            for (const [key, value] of Object.entries(data)) {
                if (key === 'image_object') {
                    // Only append if binary, otherwise its a bucket url
                    if (value && typeof value !== 'string') {
                        formData.append(key, value.rawFile);
                    }
                } else if (key === 'thumbnail') {
                    // Only append if binary, otherwise its a bucket url
                    if (value && typeof value !== 'string') {
                        formData.append(key, value.rawFile);
                    }
                }else if(key === 'collection_ids') {
                    // Create multiple entries in form with collection_ids
                    value.forEach((collection) => {
                        formData.append(key, collection);
                    })
                }else{
                    formData.append(key, value);
                }
            }

            return httpClient(data['url'], {
                method: 'PUT',
                body: formData
            })
                .then(({json}) => {
                    //Wrap response json
                    return { data: json };
                });


        }
    };
};
