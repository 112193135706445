//Demo reference from React Admin docs: https://marmelab.com/react-admin/Datagrid.html#bulkactionbuttons
import React, { useState } from 'react';
import {
    Button,
    Confirm,
    useListContext,
    useDeleteMany,
    useNotify,
    useRefresh,
    useUnselectAll,
} from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';

export const BulkCollectionDeleteManyButton = () => {
    const { selectedIds } = useListContext();
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll('collections');
    const [open, setOpen] = useState(false);


    const [deleteMany, { isLoading }] = useDeleteMany(
        'collections',
        { ids: selectedIds },
        {
            onSuccess: () => {
                //refresh();
                notify('Collection(s) deleted', { undoable: true });
                unselectAll();
            },
            onError: error => notify('Error: collection(s) not deleted', { type: 'warning' }),
            mutationMode: 'undoable',
        }
    );

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        deleteMany();
        setOpen(false);
    };

    return (
        <>
            <Button startIcon={<DeleteIcon />}  variant="outlined" color="error"  label="Delete selected collection(s)" onClick={handleClick} />
            <Confirm
                isOpen={open}
                loading={isLoading}
                title="Delete selected collection(s)"
                content="Are you sure you want to delete selected collection(s)?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};