import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import CollectionCreate01 from './images/collection_create01.jpg';
import CollectionCreate02 from './images/collection_create02.jpg';
import CollectionEdit01 from './images/collection_edit01.jpg';
import CollectionEdit02 from './images/collection_edit02.jpg';
import CollectionDelete01 from './images/collection_delete01.jpg';
import CollectionDelete02 from './images/collection_delete02.jpg';
import CollectionDeleteEdit01 from './images/collection_delete_edit01.jpg';
import CollectionDeleteEdit02 from './images/collection_delete_edit02.jpg';


const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
}));

const ImageItem = styled('img')({
  width:'100%',
});

const TextItem = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body1,
  padding: theme.spacing(2),
  textAlign: 'left',
  color: theme.palette.text.primary,
  boxShadow: 'none',
}));

export default function CollectionsTutorials() {
    return (
      <>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content-collections"
            id="panel1a-header-collections"
          >
            <Typography>Create a collection</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Grid container spacing={2}>
                <Grid md={3}>
                  <TextItem>
                    <h4>Step 1.</h4>
                    <p>Click "Collections" in the menu on the left to browse collections</p>
                    <h4>Step 2.</h4>
                    <p>Click "CREATE" button at top of the table to enter create view</p>
                    <h4>Step 3.</h4>
                    <p>Enter information (e.g. title, notes, pin) for the collection in the input fields</p>
                    <h4>Step 4.</h4>
                    <p>Click "Save" button at the bottom. </p>
                    <p>Done.</p>
                  </TextItem>
                </Grid>
                <Grid md={9}>
                  <Item><ImageItem src={CollectionCreate01} /></Item>
                  <Item><ImageItem src={CollectionCreate02} /></Item>
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content-collections"
            id="panel2a-header-collections"
          >
            <Typography>Edit a collection</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Grid container spacing={2}>
                <Grid md={3}>
                  <TextItem>
                    <h4>Step 1.</h4>
                    <p>In collections page, click on "Edit" button on the right of the collection to enter edit view.</p>
                    <h4>Step 2.</h4>
                    <p>In edit view,  you can edit information in the input fields.</p>
                    <h4>Step 3.</h4>
                    <p>Click "Save" button to save your changes.</p>
                    <p>Done.</p>
                  </TextItem>
                </Grid>
                <Grid md={9}>
                  <Item><ImageItem src={CollectionEdit01} /></Item>
                  <Item><ImageItem src={CollectionEdit02} /></Item>
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content-collections"
            id="panel3a-header-collections"
          >
            <Typography>Delete single/multiple collection</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Grid container spacing={2}>
                <Grid md={3}>
                  <TextItem>
                    <h4>Step 1.</h4>
                    <p>Click the checkbox on the left to select the collection.</p>
                    <h4>Step 2.</h4>
                    <p>Click "DELETE SELECTED COLLECTION(S)" button on top right of the table.</p>
                    <h4>Step 3.</h4>
                    <p>Click "CONFRIM" button to delete the selected collection(s).</p>
                    <p>Done.</p>
                  </TextItem>
                </Grid>
                <Grid md={9}>
                  <Item><ImageItem src={CollectionDelete01} /></Item>
                  <Item><ImageItem src={CollectionDelete02} /></Item>
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content-collections"
            id="panel3a-header-collections"
          >
            <Typography>Delete a collection in edit view</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Grid container spacing={2}>
                <Grid md={3}>
                  <TextItem>
                    <h4>Step 1.</h4>
                    <p>In edit view, click "DELETE" button at the bottom right.</p>
                    <h4>Step 2.</h4>
                    <p>Click "CONFRIM" button to delete the collection</p>
                    <p>Done.</p>
                  </TextItem>
                </Grid>
                <Grid md={9}>
                  <Item><ImageItem src={CollectionDeleteEdit01} /></Item>
                  <Item><ImageItem src={CollectionDeleteEdit02} /></Item>
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      </>
    );
}