import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    ImageField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    ReferenceInput,
    ReferenceArrayInput,
    SelectInput,
    SelectArrayInput,
    TextInput,
    ImageInput,
    useNotify,
    useRefresh,
    useRedirect,
    SearchInput,
    required,
    BulkDeleteButton,
    useResourceContext,
    useRecordContext,
    Show,
    SimpleShowLayout,
    ArrayField,
    AutocompleteInput,
    AutocompleteArrayInput,
    Toolbar,
    SaveButton,
    DeleteButton,
} from 'react-admin';
import { useDataProvider } from 'react-admin';
import { useMutation } from 'react-query';
import { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BulkAddCollectionButton from './customActions/bulkAddCollectionButton';
import InfoAlertBox from './InfoAlertBox'

// Custom image filters, used in list view
const imageFilters = [
    <TextInput source="q" label="Search" alwaysOn />,
    <ReferenceInput
        source="collections_id"
        label="Collections"
        reference="collections"
        allowEmpty
        alwaysOn>
        <SelectInput optionText="title" />
    </ReferenceInput>
];

// Image list view
export const ImageList = (props) => {
    // Bulk actions Component
    const PostBulkActionButtons = () => {
        return (
            <>
                <BulkAddCollectionButton label="Add To Collection" />
                {/* default bulk delete action */}
                {/* <BulkDeleteButton />  */}
            </>
        )
    };
    return (
        <>
        <InfoAlertBox text="Reminder: stable internet connection is required to upload images." />
        <List filters={imageFilters} {...props}>
            <Datagrid rowClick="show" bulkActionButtons={<PostBulkActionButtons />}>
                <TextField source="id" />
                <TextField source="title" />
                <TextField source="location" />
                <ImageField source="image_object" title="title"  />
                <DateField source="created" />
                <DateField source="updated" />
                <ReferenceArrayField label="Collections" reference="collections" source="collection_ids">
                    <SingleFieldList>
                        <ChipField source="title" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <EditButton />
            </Datagrid>
        </List>
        </>
    );
};


// Custom component for displaying image title
const ImageTitle = ({ record }) => {
    return <span>Image {record ? `"${record.title}"` : ''}</span>;
};

// Remove delete option for images
const ImageEditToolbar = (props) => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

// Image editing view
const CustomToolbar = props => (
    <Toolbar
        {...props}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
    >
        <SaveButton />
        {/* <DeleteButton mutationMode="pessimistic" /> */}
    </Toolbar>
  );

export const ImageEdit = (props) => (
    <Edit title={<ImageTitle />} {...props}>
        <SimpleForm toolbar={<ImageEditToolbar />}>
            <TextInput source="title" />
            <TextInput source="location" />
            <ImageField source="image_object" label="Current Image" sx={{'.RaImageField-image': {width: '400px', height: 'auto'}}}/>
            <ImageInput source="image_object" label="Add New Image Object" accept="image/*">
                <ImageField source="src" title="title" label="New Image"  />
            </ImageInput>
            <ReferenceArrayInput label="collections" source="collection_ids" reference="collections">
                <SelectArrayInput optionText="title"/>
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
);

// Custom component for displaying an images distinct tags
const AnnotationTags = () => {
    const record = useRecordContext();
    
    if (!record) return null;
    const annotation_tags = new Set(record.annotation_tags);
    const arraytest = [];
    for (const item of annotation_tags) {
        arraytest.push({tag: item});
    }

    const newRecord = {...record, annotation_tags: arraytest}

    return (
        <ArrayField record={newRecord} source='annotation_tags' label='Annotation Tags'>
            <SingleFieldList linkType={false}>
                <ChipField source='tag' />
            </SingleFieldList>
        </ArrayField>
    );
};

// Image info view
export const ImageShow = () => {
    return (
    <Show>
        <SimpleShowLayout>
            <TextField source="title" />
            <TextField source="id" label="Image ID"/>
            <DateField source="location" />
            <ImageField source="image_object" sx={{'.RaImageField-image': {width: '400px', height: 'auto'}}}/>
            <DateField source="created" />
            <DateField source="updated" />
            <ReferenceArrayField source="collection_ids" reference="collections" label="Collections">
                <SingleFieldList>
                    <ChipField source='title' />
                </SingleFieldList></ReferenceArrayField>
            <TextField label="Annotation Tags">
                <AnnotationTags />
            </TextField>
            <AnnotationTags />
        </SimpleShowLayout>
    </Show>
)};

// Image creation and edit view
export const ImageCreate = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    // Used to disable save button while upload is inprogress
    const [saveDisabled, setSaveDisabled] = React.useState(false);

    const dataProvider = useDataProvider();

    // Custom mutation to use createMany custom data provider
    const mutation = useMutation(
        (data) => dataProvider.createMany('images',data),
        {
            onError: (error, variables, context) => {
                // An error happened!
                notify(`One or more Images failed to upload due to - ${error}`, 'error');
                setSaveDisabled(false);
            },
            onSuccess: (data, variables, context) => {
                notify(`Images added`);
                // Redirect to images list with filter set to the collection that these images were uploaded to
                let redirectUrl = '/images';
                // Only redirect if there is a collection id in returned data
                if (data?.data[0]?.collection_ids[0]){
                    redirectUrl = `/images?displayedFilters=%7B%7D&filter=%7B"collection"%3A3%2C"collections_id"%3A${data?.data[0]?.collection_ids[0]}%7D`
                }
                // Enable upload button
                setSaveDisabled(false);
                redirect(redirectUrl);
                refresh();
            },
        }
    )

    // Custom image saving handler for form submission
    const imageSave = (data) => {
        // Disable upload button
        setSaveDisabled(true)
        mutation.mutate(data);
    };

    // Custom toolbar component that disables the save button when an image upload is inprogress
    const ImageCreateToolbar = () => (
        <Toolbar>
            <SaveButton label='save' disabled={saveDisabled}/>
        </Toolbar>
    )

    return (
        <Create {...props}>
            <SimpleForm onSubmit={imageSave} toolbar={<ImageCreateToolbar/>}>
                <ImageInput
                    source="image_object"
                    label="Image Object"
                    accept="image/png, image/jpeg"
                    multiple={true}
                    maxSize={10000000}
                    >
                    <ImageField source="src" title="title" />
                </ImageInput>
                <div>
                    Note: Only JPEG and PNG image files are supported
                </div>
                <div>
                    Max file size of 100MB per image
                </div>
                <br/>

                <ReferenceArrayInput
                    label="collections"
                    source="collection_ids"
                    reference="collections"
                    validate={[required()]}>
                    <AutocompleteArrayInput optionText="title" />
                </ReferenceArrayInput>
            </SimpleForm>
        </Create>
    );
};
